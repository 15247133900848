<template>
    <div class="animated fadeIn">
        <b-card class="piplines-list">
            <b-card-header class="card-header-main">
                <b-row class="piplines-list-header">
                    <b-col md="2">
                        <project-router-link to="/billing/accounts/create">
                            <b-button class="text-nowrap" variant="primary">
                                <i class="icon-plus mr-1"></i>Add new Billing Account
                            </b-button>
                        </project-router-link>
                    </b-col>
                </b-row>
            </b-card-header>
            <b-card-body>
                <wit-table
                    mode="server"
                    :fields="columns"
                    :request-function="requestFunction"
                    striped
                    paginated
                    hide-per-page-selector
                    ref="accountsTable"
                    :default-per-page="10"
                >
                    <template v-slot:cell(status)="{item}">
                        <template v-if="item.active">
                            Active subscription
                        </template>
                        <template v-else>
                            No subscription
                        </template>
                    </template>
                    <template v-slot:cell(actions)="{item}">
                        <div class="button-row">
                            <DetailsAction :routerLink="`/billing/accounts/${item.id}`" />
                            <span
                                v-b-tooltip.hover
                                title="Configure payments"
                                v-if="!item.paymentMethods.length && !item.budget"
                            >
                                <project-router-link
                                    class="configure-payments-resource"
                                    :to="`/billing/accounts/${item.id}?configure=true`"
                                >
                                    <i class="fa fa-credit-card pointer-action"> </i>
                                </project-router-link>
                            </span>
                            <span
                                v-b-tooltip.hover
                                title="Deactivate"
                                v-if="
                                    new Date(item.trialEnd).getTime() < new Date().getTime() &&
                                        item.active &&
                                        !item.budget
                                "
                            >
                                <project-router-link
                                    class="deactivate-resource"
                                    :to="`/billing/accounts/deactivate/${item.id}`"
                                >
                                    <i class="fa fa-pause pointer-action"> </i>
                                </project-router-link>
                            </span>
                            <span
                                v-b-tooltip.hover
                                title="Activate"
                                v-if="
                                    new Date(item.trialEnd).getTime() < new Date().getTime() &&
                                        !item.active &&
                                        item.paymentMethods.length &&
                                        !item.budget
                                "
                                class="activate-resource"
                                @click="() => showActivate(item)"
                            >
                                <i class="fa fa-play pointer-action"> </i>
                            </span>
                        </div>
                    </template>
                </wit-table>
            </b-card-body>
        </b-card>

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
        <wit-notification
            v-model="showPaymentNotification"
            title="Hey, we need more info from You!"
            description="To activate your account, configure your payment method
                    or create a new billing account."
            position="top"
        />
        <wit-notification
            v-model="showActivationNotification"
            title="Hey, want to come back?"
            description="To activate your account, click the play icon in the desired row."
            position="top"
        />
        <wit-notification
            v-model="showConfiguredNotification"
            title="Everything is set up!"
            description="Your billing account is all set up!"
            position="top"
        />
        <WitModal v-model="showModal" title="Are you sure?" variant="primary" customClass="activate-modal" size="md">
            <div class="activate-modal__text">Are you sure you want to activate this billing account?</div>
            <b-row class="d-block text-center">
                <b-button variant="warning" size="md" @click="activateCard">Yes</b-button>
                <span class="pad-buttons" />
                <b-button variant="danger" size="md" @click="() => (showModal = false)">No</b-button>
            </b-row>
            <b-row>
                <b-col>
                    <Feedback v-if="formError" :state="formError.state" :invalid="formError.message"></Feedback>
                </b-col>
            </b-row>
        </WitModal>
    </div>
</template>

<script>
import VueNotifications from 'vue-notifications'

import DetailsAction from '@/components/Actions/DetailsAction.vue'
import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'
import WitModal from '@/components/Modals/WitModal'
import WitNotification from '@/components/WitNotification.vue'
import WitTable from '@/components/WitTable.vue'

export default {
    data() {
        return {
            apiUrl: `${process.env.VUE_APP_NODE_API_HOST}/billingAccount`,
            columns: ['name', 'status', 'actions'],
            reloadInterval: null,
            collectError: {
                state: null,
                message: null,
            },
            showPaymentNotification: false,
            showActivationNotification: false,
            showConfiguredNotification: false,
            showModal: false,
            billingAccount: null,
            formError: {
                state: true,
                message: null,
            },
        }
    },
    components: {
        Feedback,
        Loading,
        DetailsAction,
        WitModal,
        WitNotification,
        WitTable,
    },
    notifications: {
        accountCreated: {
            type: VueNotifications.types.success,
            title: 'Billing Account has been successfully created.',
        },
        accountUpdated: {
            type: VueNotifications.types.success,
            title: 'Billing Account has been successfully updated.',
        },
        accountDeactivated: {
            type: VueNotifications.types.success,
            title: 'Billing Account has been successfully deactivated.',
        },
    },
    watch: {
        $route(to, from) {
            this.checkRoute()
        },
    },
    methods: {
        async requestFunction(params) {
            const response = await this.axios
                .get(this.apiUrl, {
                    params: {
                        ...params,
                        ascending: 'asc',
                    },
                })
                .catch(
                    function(e) {
                        this.dispatch('error', e)
                    }.bind(this)
                )

            const accounts = await Promise.all(
                response.data.data.map(async account => {
                    const {
                        data: {data},
                    } = await this.axios.get(
                        `${process.env.VUE_APP_NODE_API_HOST}/billingAccount/${account.id}/paymentMethods`
                    )

                    return {
                        ...account,
                        paymentMethods: data,
                    }
                })
            )

            return {
                data: accounts,
                count: response.data.metadata.count,
            }
        },
        reloadTable() {
            if (!document.hidden && this.$refs.accountsTable) this.$refs.accountsTable.fetchData({silent: true})
        },
        async checkRoute() {
            const {accountCreated, accountUpdated, billing_info, activate, configured} = this.$route.query

            if (accountCreated) {
                this.$router.replace({query: {}})
                this.accountCreated()
            }

            if (accountUpdated) {
                this.$router.replace({query: {}})
                this.accountUpdated()
            }

            if (activate) {
                this.showActivationNotification = true
            }

            if (billing_info) {
                this.showPaymentNotification = true
            }

            if (configured) {
                this.showConfiguredNotification = true
            }
        },
        showActivate(billingAccount) {
            this.billingAccount = billingAccount
            this.showModal = true
        },
        async activateCard() {
            try {
                this.$store.commit('loading/PROCESSING', `Activating...`)
                this.formError = {
                    state: true,
                    message: '',
                }
                const response = await this.axios.post(`${process.env.VUE_APP_NODE_API_HOST}/billingAccount/activate`, {
                    billingAccountId: this.billingAccount.id,
                    paymentMethod: this.billingAccount.paymentMethod,
                })

                await this.reloadTable()
                await this.$store.dispatch('billing/getAccounts')
                this.$store.commit('loading/PROCESSED')
                this.showModal = false
            } catch (exception) {
                this.formError.state = false
                this.formError.message = exception.response.data.data
                this.$store.commit('loading/PROCESSED')
                this.$errorHandler.report(exception, 'Could not activate billing account')
            }
        },
    },
    created() {
        this.checkRoute()
    },
    mounted() {
        this.reloadInterval = setInterval(this.reloadTable, 60000)
    },
    beforeDestroy() {
        clearInterval(this.reloadInterval)
    },
}
</script>

<style lang="scss">
.configure-payments-resource i {
    font-weight: 600;
    color: red;
}

.configure-payments-resource:hover {
    text-decoration: none;
}

.activate-modal {
    header {
        h5 {
            font-size: 0.9rem;
        }
    }

    .modal-body {
        .activate-modal__text {
            margin-bottom: 15px;
        }
    }
}
</style>
