<template>
    <div
        :class="`wit-notification wit-notification__${value ? 'open' : 'close'} ${customClass ? customClass : ''}`"
        v-bind:style="style"
        :id="id"
    >
        <div class="wit-notification__button">
            <b-button-close @click="close" />
        </div>
        <div class="wit-notification__body">
            <div class="wit-notification__photo">
                <img src="@/assets/img/teacher.png" alt />
            </div>
            <div class="wit-notification__text">
                <p class="wit-notification__text-headline">
                    {{ title }}
                </p>
                <p class="wit-notification__text-description">
                    {{ description }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
        },
        description: {
            type: String,
        },
        size: {
            type: String,
            default: 'lg',
        },
        id: {
            type: String,
        },
        customClass: {
            type: String,
        },
        position: {
            type: String,
            default: 'top',
        },
    },
    computed: {
        style() {
            let style = {}

            if (this.position === 'top') {
                style.top = 0
            }

            if (this.position === 'bottom') {
                style.bottom = 0
            }

            return style
        },
    },
    methods: {
        close() {
            this.$emit('input', false)
        },
    },
}
</script>

<style lang="scss">
.wit-notification {
    position: absolute;
    right: 0;
    width: 350px;
    padding: 15px 25px;
    margin: 10px;
    background-color: white;
    border-radius: 15px;
    transition: all ease-in 0.5s;
    box-shadow: 8px 8px rgba(0, 0, 0, 0.1);
    opacity: 0.8;

    .wit-notification__body {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    .wit-notification__photo {
        img {
            height: 150px;
        }
        padding-right: 10px;
    }

    .wit-notification__text {
        .wit-notification__text-headline {
            font-weight: 600;
        }
    }
}

.wit-notification__open {
    opacity: 0.7;
    visibility: visible;
}

.wit-notification__close {
    opacity: 0;
    visibility: hidden;
}
</style>
